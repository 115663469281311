<template>
    <div class="popup _mask" @click="hideModal()">
        <div class="popup__inner" @click.stop>
            <div class="popup__close" title="Закрыть окно" @click="hideModal()">
                <img src="@/assets/img/svg/close.svg" alt="close" />
            </div>
            <div class="popup__cols">
                <div class="popup__img">
                    <img :src="productData.image" :alt="productData.real_name" />
                </div>
                <div class="popup__body">
                    <div class="popup__head">
                        <div class="popup__name">
                            <h2 class="popup__title">
                                {{ String(productData.real_name).split("-")[0] }}
                            </h2>
                        </div>
                        <div class="popup__ornament md">
                            <img src="@/assets/img/svg/ornament.svg" alt="arrow" />
                        </div>
                        <div class="popup__price">{{ productData.price }} ₽</div>
                    </div>
                    <div class="popup__subtitle" v-if="productData.real_name">
                        {{ String(productData.real_name).split("-")[1] }}
                    </div>
                    <div class="popup__icons">
                        <div class="popup__icon">
                            <img src="@/assets/img/icons/1.png" alt="icons" />
                        </div>
                        <div class="popup__icon">
                            <img src="@/assets/img/icons/2.png" alt="icons" />
                        </div>
                        <div class="popup__icon">
                            <img src="@/assets/img/icons/3.png" alt="icons" />
                        </div>
                        <div class="popup__icon">
                            <img src="@/assets/img/icons/4.png" alt="icons" />
                        </div>
                    </div>
                    <div class="popup__text">{{ productData.description }}</div>
                    <div class="popup__ornament mm">
                        <img src="@/assets/img/svg/ornament.svg" alt="arrow" />
                    </div>
                    <div class="popup__pars">
                        <div class="popup__par">
                            <span class="popup__val">{{ productData.weight }}</span>
                            <span class="popup__measure">грамм</span>
                        </div>
                        <div class="popup__par">
                            <span class="popup__val">{{ productData.energy }}</span>
                            <span class="popup__measure">ккал</span>
                        </div>
                        <div class="popup__par">
                            <span class="popup__val">{{ productData.proteins }}</span>
                            <span class="popup__measure">белки</span>
                        </div>
                        <div class="popup__par">
                            <span class="popup__val">{{ productData.fat }}</span>
                            <span class="popup__measure">жиры</span>
                        </div>
                        <div class="popup__par">
                            <span class="popup__val">{{ productData.carbohydrates }}</span>
                            <span class="popup__measure">углеводы</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "PopupMask",
    created() {},
    data() {
        return {
            productData: {},
        };
    },
    props: {
        id: {
            type: Number,
            default: 0,
        },
    },
    methods: {
        hideModal() {
            this.$emit("close");
        },

        fetchProductData() {
            this.productData = {};
            axios
                .get("https://tcode.online/api/tatar/products/" + this.id + "/")
                .then((response) => {
                    this.productData = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    watch: {
        id: function () {
            this.fetchProductData();
        },
    },
};
</script>

<style lang="scss">
.popup {
    background: rgba(0, 0, 0, 0.5);
    background-blend-mode: multiply;
    -webkit-overflow-scrolling: touch;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
    @media (max-width: $ms3) {
        align-items: flex-end;
    }
    &__inner {
        background-color: #fff;
        width: 100%;
        max-width: 512px;
        border-radius: 10px;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
        position: absolute;
        bottom: -100%;
        opacity: 0;
        height: 75vh !important;
        @media (max-width: $ms3) {
            border-radius: 10px 10px 0 0;
        }
        @media (max-width: $ms4) {
            max-width: 360px;
        }
    }
    &__close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        display: flex;
        width: 50px !important;
        height: 50px !important;
        cursor: pointer;
        &:hover,
        &:focus {
            opacity: 0.5;
            transition: 0.2s ease 0.2s;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &__cols {
        display: flex;
        flex-direction: column;
        @media (max-width: $ms2) {
            flex-direction: column;
        }
    }
    &__img {
        width: 100%;
        height: 400px;
        border-radius: 10px 10px 0 0;
        overflow: hidden;
        @media (max-width: $ms3) {
            height: 284px !important;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__body {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 30px 35px;
        height: 50vh;
        overflow-x: hidden !important;
        overflow-y: auto !important;
        &::-webkit-scrollbar {
            width: 2px;
            height: 100%;
        }
        &::-webkit-scrollbar-track {
            background: #eee;
            border-radius: 0;
        }
        &::-webkit-scrollbar-thumb {
            background: #2a374b;
            border-radius: 0;
            cursor: pointer;
            height: 20%;
        }
        &::-webkit-scrollbar-thumb:hover {
            opacity: 0.5;
        }
        @media (max-width: $ms3) {
            padding: 25px;
        }
    }
    &__title {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        color: #2a374b;
    }
    &__subtitle {
        font-size: 14px;
        line-height: 24px;
        color: #5a5a5a;
        font-style: italic;
        text-transform: capitalize;
        display: block;
    }
    &__icons {
        display: none;
        @media (max-width: $ms3) {
            flex-wrap: wrap;
        }
    }
    &__icon {
        width: 70px;
        height: 80px;
        margin: 1.5rem 2rem 0px 0px;
        @media (max-width: $ms3) {
            width: 35px;
            height: 40px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &__text {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #0d0d0d;
        margin-top: 10px;
        flex: 1 1 auto;
        white-space: pre-line;
        @media (max-width: $ms3) {
            font-size: 16px !important;
        }
    }
    &__pars {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    &__par {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        &:not(:last-child) {
            margin-right: 30px;
            @media (max-width: $ms4) {
                margin-right: 15px;
            }
        }
        &:first-child {
            margin-right: 45px;
            @media (max-width: $ms4) {
                margin-right: 25px;
            }
            .popup__val {
                font-weight: 600;
            }
        }
    }
    &__val {
        font-size: 14px;
        line-height: 24px;
        color: #2a374b;
    }
    &__measure {
        font-size: 10px;
        line-height: 16px;
        color: #5a5a5a;
    }
    &__ornament {
        filter: invert(0.5);
        width: 62px;
        height: 12px;
        flex: 0 0 auto;
        margin: 0 1rem;
        @media (max-width: $ms3) {
            margin: 30px 0px 10px 0px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__price {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        color: #2a374b;
        flex: 0 0 auto;
    }
}
</style>
