<template>
    <div class="reserve-popup-wrapper" v-if="show">
        <div v-if="!inputCode && !reservedData" class="reserve-popup">
            <div class="reserve-popup-header">
                <p class="reserve-popup__title">Забронировать столик</p>
                <span class="reserve-popup__close" @click="closePopup()">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 1L1 21M21 21L1 1" stroke="#B0B0B0" stroke-width="2" stroke-linejoin="round" />
                    </svg>
                </span>
            </div>
            <div v-if="state == 'first'" class="reserve-popup-body">
                <div class="form-group">
                    <label for="shop">Ресторан</label>
                    <div class="reserve-popup-dropdown">
                        <div
                            class="reserve-popup-dropdown-header"
                            @click="shop_list_active = !shop_list_active"
                            :class="shop_list_active ? 'active' : ''"
                        >
                            <p v-if="!firstFormData.shop" class="placeholder">Выберите ресторан</p>
                            <p v-else>
                                {{ shop_list.find((x) => x.value == firstFormData.shop).label }}
                                <span v-if="shop_list.find((x) => x.value == firstFormData.shop).subtitle">
                                    {{ shop_list.find((x) => x.value == firstFormData.shop).subtitle }}
                                </span>
                            </p>
                            <i class="icon" :class="shop_list_active ? 'active' : ''">
                                <svg
                                    width="18"
                                    height="10"
                                    viewBox="0 0 18 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 1.5L9 8.5L17 1.5"
                                        stroke="#5A5A5A"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </i>
                        </div>
                        <div class="reserve-popup-dropdown-body" :class="shop_list_active ? 'active' : ''">
                            <div
                                class="reserve-popup-dropdown-body__item"
                                v-for="shop in shop_list"
                                :key="shop.id"
                                @click="selectHandler('shop', shop.value)"
                            >
                                <p>
                                    {{ shop.label }}
                                    <span v-if="shop.subtitle">
                                        {{ shop.subtitle }}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!--<div class="checkbox-group">
                    <input v-model="firstFormData.is_iftar" id="iftar" type="checkbox" />
                    <label for="iftar">Приду на ифтар</label>
                </div>-->
                <div class="date-time">
                    <label for="date">Дата посещения</label>
                    <vue-date-picker
                        v-model="firstFormData.date"
                        :min-date="new Date()"
                        :format="format"
                        :disabled-dates="block_dates"
                        model-type="dd.MM.yyyy"
                        locale="ru"
                        type="date"
                        cancelText=""
                        selectText="Выбрать"
                        placeholder="Выбрать дату"
                        month-name-format="long"
                        :enable-time-picker="false"
                        disable-year-select
                        auto-apply
                        required
                        id="date"
                        :clearable="false"
                    />
                    <div class="reserve-popup-dropdown">
                        <div
                            class="reserve-popup-dropdown-header"
                            @click="time_list_active = !time_list_active"
                            :class="time_list_active ? 'active' : ''"
                        >
                            <p v-if="!firstFormData.time" class="placeholder">Выберите время</p>
                            <p v-else>
                                {{ time_list.find((x) => x.value == firstFormData.time).label }}
                            </p>
                            <i class="icon" :class="time_list_active ? 'active' : ''">
                                <svg
                                    width="18"
                                    height="10"
                                    viewBox="0 0 18 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 1.5L9 8.5L17 1.5"
                                        stroke="#5A5A5A"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </i>
                        </div>
                        <div class="reserve-popup-dropdown-body" :class="time_list_active ? 'active' : ''">
                            <div
                                class="reserve-popup-dropdown-body__item"
                                v-for="time in time_list"
                                :key="time.id"
                                @click="selectHandler('time', time.value)"
                            >
                                <p>
                                    {{ time.label }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="shop">Количество человек</label>
                    <div class="reserve-popup-dropdown">
                        <div
                            class="reserve-popup-dropdown-header"
                            @click="table_list_active = !table_list_active"
                            :class="table_list_active ? 'active' : ''"
                        >
                            <p v-if="!firstFormData.table" class="placeholder">Выберите ресторан</p>
                            <p v-else>
                                {{ table_list.find((x) => x.value == firstFormData.table).label }}
                            </p>
                            <i class="icon" :class="table_list_active ? 'active' : ''">
                                <svg
                                    width="18"
                                    height="10"
                                    viewBox="0 0 18 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 1.5L9 8.5L17 1.5"
                                        stroke="#5A5A5A"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </i>
                        </div>
                        <div class="reserve-popup-dropdown-body" :class="table_list_active ? 'active' : ''">
                            <div
                                class="reserve-popup-dropdown-body__item"
                                v-for="table in table_list"
                                :key="table.id"
                                @click="selectHandler('table', table.value)"
                            >
                                <p>
                                    {{ table.label }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="reserveUnavailable" class="error">
                    Бронирование недоступно. <br />
                    Пожалуйста, выберите другую дату.
                </div>
                <button class="btn" :disabled="first_error || reserveUnavailable" @click="state = 'second'">
                    Продолжить
                </button>
            </div>
            <div v-if="state == 'second'" class="reserve-popup-body">
                <div class="form-group">
                    <label for="first_name">Имя</label>
                    <input
                        id="first_name"
                        type="text"
                        class="input"
                        placeholder="Введите имя"
                        v-model.trim="secondFormData.first_name"
                        v-on:input="formValidate('second')"
                    />
                </div>
                <div class="form-group">
                    <div class="row">
                        <label for="phone">Телефон</label>
                        <span
                            >Указывая телефон, вы принимаете
                            <conf />
                        </span>
                    </div>
                    <input
                        id="phone"
                        v-model.trim="secondFormData.phone"
                        type="tel"
                        class="input"
                        placeholder="+7 900 000 00 00"
                        v-mask="'+7 ### ### ## ##'"
                        v-on:input="formValidate('second')"
                    />
                </div>
                <div class="form-group">
                    <label for="comment">Пожелания к брони</label>
                    <input
                        id="comment"
                        v-model.trim="secondFormData.comment"
                        type="text"
                        class="input"
                        placeholder="Напишите пожелания"
                        v-on:input="formValidate('second')"
                    />
                </div>
                <div class="error">{{ error }}</div>
                <button class="btn" :disabled="second_error" @click="reservationAdd()">Забронировать</button>
            </div>
        </div>
        <div v-else-if="inputCode && !reservedData" class="reserve-popup">
            <div class="reserve-popup-header">
                <p class="reserve-popup__title">
                    <span>Введите код подтверждения бронирования.</span>
                    <span>Мы отправили его на номер</span>
                    <span>{{ secondFormData.phone }}</span>
                </p>
                <p class="reserve-popup__title"></p>
                <span class="reserve-popup__close" @click="closePopup()">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 1L1 21M21 21L1 1" stroke="#B0B0B0" stroke-width="2" stroke-linejoin="round" />
                    </svg>
                </span>
            </div>
            <div class="reserve-popup-body">
                <input type="tel" id="code" class="input" placeholder="Введите код" v-model.trim="code" />
                <span class="error" v-if="code_error">Неверный код из смс!</span>
                <button class="btn" :disabled="!code || code.length != 4" @click="reservationСonfirm()">
                    Подтвердить
                </button>
            </div>
        </div>
        <div v-else class="reserve-popup">
            <div class="reserve-popup-header">
                <p class="reserve-popup__title">
                    Ваше бронирование <br />
                    оформлено
                </p>
                <p class="reserve-popup__title"></p>
                <span class="reserve-popup__close" @click="closePopup()">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 1L1 21M21 21L1 1" stroke="#B0B0B0" stroke-width="2" stroke-linejoin="round" />
                    </svg>
                </span>
            </div>
            <div class="reserve-popup-body">
                <div class="reserve-popup-body__reserved">
                    <span>{{ shop_list.find((x) => x.id == reservedData.shop).label }}</span>
                    <span>
                        {{ new Date(reservedData.reserved_time).toLocaleDateString() }},
                        {{ firstFormData.time }}
                    </span>
                    <span>{{ reservedData.table }}</span>
                    <i class="icon">
                        <svg width="64" height="14" viewBox="0 0 64 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                opacity="0.4"
                                d="M49.619 7C55.3333 7 56.1667 4.39623 56.2857 1C57.4762 1.79245 58.9524 3.37736 58.4762 5.18868C59.9048 5.18868 62.1667 5.98113 63 7C62.1667 8.01887 59.9048 8.81132 58.4762 8.81132C58.9524 10.6226 57.4762 12.2075 56.2857 13C56.1667 9.60377 55.3333 6.99999 49.619 7ZM49.619 7L14.381 7M14.381 7C8.66666 7 7.83333 4.39622 7.71429 0.999998C6.52381 1.79245 5.04762 3.37736 5.52381 5.18868C4.09524 5.18868 1.83333 5.98113 1 7C1.83333 8.01887 4.09524 8.81132 5.52381 8.81132C5.04762 10.6226 6.52381 12.2075 7.71429 13C7.83333 9.60377 8.66666 6.99999 14.381 7Z"
                                stroke="#2A374B"
                            />
                        </svg>
                    </i>
                    <span class="subtle">
                        В ближайшее время Вам придет сообщение о статусе бронирования<br />
                        *В случае подтверждения, бронь будет действительна в течении 20 минут
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import axios from "axios";
import Conf from "../UI/Conf.vue";

export default {
    data() {
        return {
            isModalVisible: false,
            show: false,
            state: "first",
            shop_list: [
                {
                    id: 3,
                    label: "Кремль, 5",
                    subtitle: "(Казанский кремль)",
                    value: "tatar",
                },
                {
                    id: 21,
                    label: "Марджани, 4",
                    subtitle: "(Старо-татарская слобода)",
                    value: "marjani",
                },
            ],
            shop_list_active: false,
            block_dates: ["2025-03-29"],
            format: (date) => {
                const day = String(date.getDate()).padStart(2, "0");
                const month = String(date.getMonth() + 1).padStart(2, "0");
                const year = date.getFullYear();
                return `${day}.${month}.${year}`;
            },
            time_list: [
                {
                    id: 0,
                    label: "10:00",
                    value: "10:00",
                },
                {
                    id: 1,
                    label: "11:00",
                    value: "11:00",
                },
                {
                    id: 2,
                    label: "12:00",
                    value: "12:00",
                },
                {
                    id: 3,
                    label: "13:00",
                    value: "13:00",
                },
                {
                    id: 4,
                    label: "14:00",
                    value: "14:00",
                },
                {
                    id: 5,
                    label: "15:00",
                    value: "15:00",
                },
                {
                    id: 6,
                    label: "16:00",
                    value: "16:00",
                },
                {
                    id: 7,
                    label: "17:00",
                    value: "17:00",
                },
                {
                    id: 8,
                    label: "18:00",
                    value: "18:00",
                },
                {
                    id: 9,
                    label: "19:00",
                    value: "19:00",
                },
                {
                    id: 10,
                    label: "20:00",
                    value: "20:00",
                },
            ],
            time_list_active: false,
            table_list: [
                {
                    id: 0,
                    label: "2 человека",
                    value: "2 человека",
                },
                {
                    id: 1,
                    label: "4 человека",
                    value: "4 человека",
                },
                {
                    id: 2,
                    label: "6 человек",
                    value: "6 человек",
                },
                {
                    id: 3,
                    label: "8 человек",
                    value: "8 человек",
                },
            ],
            table_list_active: false,
            firstFormData: {
                shop: null,
                is_iftar: false,
                date: new Date().toLocaleDateString(),
                time: "10:00",
                table: "2 человека",
            },
            first_error: true,
            secondFormData: {
                first_name: null,
                phone: null,
                comment: null,
            },
            second_error: true,
            reservationID: null,
            inputCode: false,
            code_error: false,
            code: null,
            reservedData: null,
            reserveUnavailable: false,
            error: "",
        };
    },

    components: {
        VueDatePicker,
        Conf,
    },

    methods: {
        openPopup() {
            this.show = true;
        },
        openPopup1() {
            this.show = true;
            this.firstFormData.shop = "tatar";
            this.isModalVisible = true;
        },
        openPopup2() {
            this.show = true;
            this.firstFormData.shop = "marjani";
            this.isModalVisible = true;
        },
        closePopup() {
            this.isModalVisible = false;
            this.state = "first";
            this.first_error = true;
            this.firstFormData = {
                shop: null,
                is_iftar: false,
                date: new Date().toLocaleDateString(),
                time: "10:00",
                table: "2 человека",
            };
            this.second_error = true;
            this.secondFormData = {
                first_name: null,
                phone: null,
                comment: null,
            };
            this.inputCode = false;
            this.reservationID = null;
            this.code = null;
            this.show = false;
            this.error = "";
        },

        selectHandler(type, value) {
            if (type == "shop") {
                this.firstFormData.shop = value;
                this.shop_list_active = false;
            } else if (type == "time") {
                this.firstFormData.time = value;
                this.time_list_active = false;
            } else if (type == "table") {
                this.firstFormData.table = value;
                this.table_list_active = false;
            }

            this.formValidate("first");
        },

        formValidate(type) {
            if (type == "first") {
                let errors = 4;
                for (let value in this.firstFormData) {
                    if (value !== "is_iftar") {
                        if (!this.firstFormData[value] || this.firstFormData[value] == "") {
                            errors++;
                        } else {
                            errors--;
                        }
                    }
                }

                if (errors != 0) {
                    this.first_error = true;
                } else {
                    this.first_error = false;
                }
            } else if (type == "second") {
                let errors = 2;
                for (let value in this.secondFormData) {
                    if (value !== "comment") {
                        if (!this.secondFormData[value] || this.secondFormData[value] == "") {
                            errors++;
                        } else {
                            if (value == "phone") {
                                if (this.secondFormData[value].length != 16) {
                                    errors++;
                                } else {
                                    errors--;
                                }
                            } else {
                                errors--;
                            }
                        }
                    }
                }

                if (errors != 0) {
                    this.second_error = true;
                } else {
                    this.second_error = false;
                }
            }
        },

        fetchDisabledDates(shop) {
            axios
                .get(`https://admin.tcode.online/api/projects/tatar/shops/${shop}/block-dates/`)
                .then((response) => {
                    const currentDate = this.firstFormData.date.split(".").reverse().join("-");
                    if (response.data.length != 0) {
                        const array = [];
                        for (let i = 0; i < response.data.length; i++) {
                            if (currentDate == response.data[i].date) {
                                console.log(currentDate);
                                console.log(response.data[i].date);
                                this.reserveUnavailable = true;
                                return;
                            } else {
                                this.reserveUnavailable = false;
                            }
                            array.push(new Date(response.data[i].date).toDateString());
                        }
                        this.block_dates = array;
                    } else {
                        this.block_dates = null;
                    }
                })
                .catch((promise) => {
                    console.log(promise);
                });
        },

        reservationAdd() {
            if (this.firstFormData.shop) {
                const reserved_time = `${this.firstFormData.date.split(".").reverse().join("-")} ${
                    this.firstFormData.time
                }`;

                axios
                    .post(
                        `https://admin.tcode.online/api/projects/tatar/shops/${this.firstFormData.shop}/reservation-add/`,
                        {
                            reserved_time: reserved_time,
                            phone: this.secondFormData.phone,
                            table: this.firstFormData.table,
                            first_name: this.secondFormData.first_name,
                            comment: this.secondFormData.comment,
                            type: this.firstFormData.is_iftar ? "iftar" : "table",
                        }
                    )
                    .then((response) => {
                        if (response.data.is_reserved) {
                            this.inputCode = true;
                            this.reservationID = response.data.id;
                        }
                    })
                    .catch((promise) => {
                        console.log(promise);
                        this.error = promise.message;
                    });
            }
        },

        reservationСonfirm() {
            if (this.firstFormData.shop && this.reservationID && this.code) {
                axios
                    .post(
                        `https://admin.tcode.online/api/projects/tatar/shops/${this.firstFormData.shop}/reservation-confirm/`,
                        {
                            reservation_id: this.reservationID,
                            verification_code: this.code,
                        }
                    )
                    .then((response) => {
                        if (response.data.is_reserved) {
                            this.reservedData = response.data;
                            this.error = "";
                        }
                    })
                    .catch((promise) => {
                        console.log(promise);
                        if (promise.response.data.error == "Incorrectly verification_code") {
                            this.code_error = true;
                        }
                    });
            }
        },
    },

    watch: {
        firstFormData: {
            handler: function (newValue) {
                if (newValue.shop && newValue.date) {
                    this.fetchDisabledDates(newValue.shop);
                }
            },
            deep: true,
        },
        isModalVisible: function () {
            if (this.isModalVisible) {
                document.documentElement.classList.add("hidden");
            } else {
                document.documentElement.classList.remove("hidden");
            }
        },
    },
};
</script>
<style lang="scss">
@import "@/assets/scss/popup-form.scss";
</style>
