<template>
    <div class="login-popup">
        <div class="login-popup__popup" @click.stop>
            <div class="login-popup__back" v-if="verInputCode" @click="verInputCode = false">
                <img src="@/assets/img/svg/arrow_back.svg" alt="close" />
            </div>
            <div class="login-popup__close" @click="closeModal">
                <img :src="require(`@/assets/img/${close_img}`)" :alt="close_img" />
            </div>
            <div class="login-popup__body">
                <h2 class="login-popup__title">{{ title }}</h2>
                <p class="login-popup__subtitle">{{ subtitle }}</p>
                <div v-if="isInputCode">
                    <template v-if="verInputCode">
                        <div class="login-popup__ver-code">
                            <input type="tel" name="code" v-model="verCode" maxlength="4" :placeholder="placeholder" />
                        </div>
                    </template>
                    <template v-else>
                        <div class="login-popup__input">
                            <input
                                type="text"
                                name="tel"
                                v-model="phone"
                                :placeholder="placeholder"
                                v-mask="'+7 ### ### ## ##'"
                                @keyup.enter="submit"
                                id="tel"
                            />
                        </div>
                    </template>
                    <div class="login-popup__error">
                        {{ verCodeError }}
                    </div>
                    <div class="login-popup__agree" v-if="isAgree">
                        <span>{{ agree }}</span>
                        <conf />
                    </div>
                    <button class="btn-login-popup" @click="setNumberPhone()" v-if="btnPostPhone">Продолжить</button>
                    <button class="btn-login-popup" @click="validateCode()" v-if="btnPostCode">Продолжить</button>
                </div>
                <div v-if="isInputName">
                    <div class="login-popup__input">
                        <input type="text" name="name" v-model="name" :placeholder="placeholder" id="name" />
                    </div>
                    <button class="btn-login-popup" @click="inSign()">Войти</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Conf from "../UI/Conf.vue";
export default {
    components: { Conf },
    name: "LoginPopup",
    data() {
        return {
            title: `Войдите в личный\n кабинет`,
            subtitle: `Укажите номер телефона и получайте\n кэшбэк 5% с каждого заказа`,
            close_img: `svg/close_popup.svg`,
            placeholder: `+7 900 000 00 00`,
            agree: `Указывая номер телефона, вы принимаете `,
            phone: "",
            id_project: 2,
            verCode: "",
            verInputCode: false,
            verCodeError: "",
            isAgree: true,
            isInputCode: true,
            name: "",
            isInputName: false,
            btnPostPhone: true,
            btnPostCode: false,
        };
    },
    methods: {
        setNumberPhone() {
            if (this.phone !== "") {
                axios
                    .post("https://admin.tcode.online/api/projects/tatar/auth/phone/", {
                        phone: this.phone,
                    })
                    .then(() => {
                        this.verInputCode = true;
                        this.title = `Войдите в личный кабинет`;
                        this.subtitle = `Введите код\n из СМС-сообщения`;
                        this.btnPostPhone = false;
                        this.btnPostCode = true;
                        this.placeholder = "0   0   0   0";
                    })
                    .catch((error) => {
                        console.log(error.response);
                    });
            }
        },
        validateCode() {
            const phone = this.phone.replace(/\s+/g, "").slice(1);

            axios
                .post("https://admin.tcode.online/api/projects/tatar/auth/verification/", {
                    phone: phone,
                    code: this.verCode,
                })
                .then((response) => {
                    //this.$emit("auth", response.data.token);
                    this.verCodeError = "";
                    this.isInputCode = false;
                    this.isInputName = true;
                    this.subtitle = `Укажите свое имя для\n завершения регистрации`;
                    this.placeholder = "Введите имя";
                    localStorage.setItem("token", response.data.token);
                })
                .catch((error) => {
                    console.log(error.response);
                    this.verCodeError = "Неверный код!";
                });
        },
        inSign() {
            if (this.name !== "" && this.name.length >= 2) {
                axios
                    .post(
                        "https://admin.tcode.online/api/projects/tatar/profile/",
                        {
                            first_name: this.name,
                        },
                        {
                            headers: {
                                Authorization: "Token " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then((response) => {
                        console.log(response.data);
                        this.isInputName = false;
                        this.title = `Спасибо.\nВы успешно авторизованы!`;
                        this.subtitle = "";
                        setTimeout(() => {
                            this.$emit("auth", localStorage.getItem("token"));
                            window.location.replace("/account");
                        }, 5000);
                    })
                    .catch((error) => {
                        console.log(error.response);
                    });
            }
        },
        closeModal() {
            this.verInputCode = false;
            this.$emit("close");
        },
    },
};
</script>

<style lang="scss">
.login-popup {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    border: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    opacity: 0;
    animation: showLoginPopup 0.2s forwards 0.2s;
    @keyframes showLoginPopup {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @media (max-width: $ms3) {
        align-items: flex-end;
    }
    &__popup {
        width: 100%;
        max-width: 512px;
        height: auto;
        min-height: 400px;
        background-color: #fefefe;
        border-radius: 10px;
        position: relative;
        @media (max-width: $ms3) {
            max-width: 100%;
            border-radius: 10px 10px 0 0;
        }
    }
    &__close,
    &__back {
        position: absolute;
        top: 20px;
        cursor: pointer;
        height: 20px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        &:hover,
        &:focus {
            opacity: 0.5;
            transition: 0.2s ease 0.2s;
        }
    }
    &__close {
        right: 20px;
        width: 20px;
        z-index: 1;
    }
    &__back {
        left: 20px;
        width: 10px;
    }
    &__body {
        width: 100%;
        max-width: 430px;
        text-align: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 50px 20px 20px;
    }
    &__title {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        color: #2a374b;
        white-space: pre;
    }
    &__subtitle {
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #0d0d0d;
        margin-top: 2rem;
        white-space: pre;
    }
    &__input {
        border: 1px solid #b0b0b0;
        border-radius: 10px;
        overflow: hidden;
        width: 322px;
        height: 56px;
        margin-top: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #000;
        @media (max-width: $ms3) {
            width: 100%;
        }
        input {
            width: 100%;
            font-size: 14px;
            line-height: 24px;
            color: #b0b0b0;
            background: none;
            padding: 0 1rem;
            font-family: "Montserrat";
        }
    }
    &__agree {
        font-size: 8px;
        font-weight: 400;
        line-height: 14px;
        color: #b0b0b0;
        text-align: center;
        display: flex;
        flex-direction: column;
        a {
            color: inherit;
            text-decoration: underline;
            &:hover,
            &:focus {
                text-decoration: none;
                transition: 0.2s ease 0.2s;
            }
        }
    }
    &__ver-code {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
        input {
            border: 1px solid #b0b0b0;
            border-radius: 10px;
            font-weight: 600;
            font-size: clamp(16px, 5vw, 22px);
            line-height: clamp(21.82px, 5vw, 30.01px);
            color: #373737;
            text-align: center;
            width: 100%;
            max-width: 321px;
            height: 56px;
            outline: none;
            &::placeholder {
                color: #b0b0b0;
            }
        }
    }
    &__error {
        font-size: 14px;
        color: #dc3545;
        height: 1rem;
    }
    &__buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
        width: 100%;
    }
    &__repeat,
    &__edit {
        font-size: 20px;
        line-height: 27.28px;
        color: #d07f67;
        cursor: pointer;
        text-align: center;
        @media (max-width: $ms3) {
            font-size: 16px;
        }
        @media (max-width: $ms4) {
            font-size: 12px;
        }
        &:hover,
        &:focus {
            color: #cf6545;
            transition: 0.2s ease 0.2s;
        }
    }
    .btn-login-popup {
        width: 100%;
        max-width: 240px;
        min-height: 56px;
        background: #2a374b;
        border-radius: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        color: #fff;
        font-family: "Montserrat";
        margin-top: 35px;
    }
}
</style>
