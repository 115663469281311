<template>
    <div class="popup _mask" @click="hideModal()" v-if="discount.id === popupId">
        <div class="popup__inner" @click.stop>
            <div class="popup__close" title="Закрыть окно" @click="hideModal()">
                <img src="@/assets/img/svg/close.svg" alt="close" />
            </div>
            <div class="popup__cols">
                <div class="popup__img">
                    <img :src="require(`@/assets/img/discounts/${discount.img}`)" :alt="discount.title" />
                </div>
                <div class="popup__body">
                    <div class="popup__head">
                        <div class="popup__name">
                            <h2 class="popup__title">{{ discount.title }}</h2>
                            <span class="popup__subtitle"></span>
                        </div>
                    </div>
                    <div class="popup__text" v-html="discount.full_text"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PopupMaskDisc",
    methods: {
        hideModal() {
            this.$emit("close");
        },
    },
    props: ["discount", "popupId"],
};
</script>

<style lang="scss">
.popup {
    background: rgba(0, 0, 0, 0.5);
    background-blend-mode: multiply;
    -webkit-overflow-scrolling: touch;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
    @media (max-width: $ms3) {
        align-items: flex-end;
    }
    &__inner {
        background-color: #fff;
        width: 100%;
        max-width: 512px;
        height: auto;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
        @media (max-width: $ms3) {
            border-radius: 10px 10px 0 0;
        }
        @media (max-width: $ms4) {
            max-width: 360px;
        }
    }
    &__close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        display: flex;
        width: 20px;
        height: 20px;
        cursor: pointer;
        &:hover,
        &:focus {
            opacity: 0.5;
            transition: 0.2s ease 0.2s;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &__cols {
        display: flex;
        flex-direction: column;
        @media (max-width: $ms2) {
            flex-direction: column;
        }
    }
    &__img {
        width: 100%;
        height: 400px;
        border-radius: 10px 10px 0 0;
        overflow: hidden;
        @media (max-width: $ms3) {
            height: 284px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__body {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 30px 35px;
        @media (max-width: $ms3) {
            padding: 25px;
        }
    }
    &__title {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        color: #2a374b;
    }
    &__subtitle {
        font-size: 14px;
        line-height: 24px;
        color: #5a5a5a;
        font-style: italic;
    }
    &__icons {
        display: none;
        @media (max-width: $ms3) {
            flex-wrap: wrap;
        }
    }
    &__icon {
        width: 70px;
        height: 80px;
        margin: 1.5rem 2rem 0px 0px;
        @media (max-width: $ms3) {
            width: 35px;
            height: 40px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &__text {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #0d0d0d;
        margin-top: 10px;
        flex: 1 1 auto;
        white-space: pre-line;
    }
    &__pars {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    &__par {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        &:not(:last-child) {
            margin-right: 30px;
            @media (max-width: $ms4) {
                margin-right: 15px;
            }
        }
        &:first-child {
            margin-right: 45px;
            @media (max-width: $ms4) {
                margin-right: 25px;
            }
            .popup__val {
                font-weight: 600;
            }
        }
    }
    &__val {
        font-size: 14px;
        line-height: 24px;
        color: #2a374b;
    }
    &__measure {
        font-size: 10px;
        line-height: 16px;
        color: #5a5a5a;
    }
    &__ornament {
        filter: invert(0.5);
        width: 62px;
        height: 12px;
        flex: 0 0 auto;
        margin: 0 1rem;
        @media (max-width: $ms3) {
            margin: 30px 0px 10px 0px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__price {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        color: #2a374b;
        flex: 0 0 auto;
    }
}
</style>
