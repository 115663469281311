<template>
    <div class="popupForm">
        <div class="popupForm__container" @click.stop>
            <div class="popupForm__close" @click="$emit('closePopupFeedback')">
                <img src="@/assets/img/svg/close_popup.svg" alt="close" />
            </div>
            <h2 class="popupForm__fanks" v-if="isFanks">{{ fanks }}</h2>
            <div class="popupForm__body" v-else>
                <h1 class="popupForm__title">{{ title }}</h1>
                <form @submit.prevent="validForm" class="form">
                    <div class="popupForm__stars">
                        <input type="hidden" name="rating" v-model="rating" />
                        <div
                            class="star"
                            v-for="(star, index) in starLimit"
                            :key="index"
                            @click="setRatingStar(star)"
                            :class="rating >= star ? 'star-active' : ''"
                        ></div>
                    </div>
                    <input type="hidden" required name="shop" id="shop" v-model="shop_name" />
                    <div id="selectedRest" :style="shop_name ? 'background: #f3f3f3' : ''">
                        <div class="block-flex">
                            <div
                                class="currentRest"
                                :style="selectStreet === 'Выберите ресторан' ? 'color:#B0B0B0' : 'color:#2A374B'"
                            >
                                {{ selectStreet }} <span>{{ selectLocation }}</span>
                            </div>
                            <img :src="require('@/assets/img/svg/select-arrow.svg')" alt="arrow" class="arrow" />
                        </div>
                        <div class="listRest">
                            <div
                                class="nameRest"
                                :value="option.value"
                                v-for="option in options"
                                :key="option.id"
                                @click="
                                    (shop_name = option.value),
                                        (selectStreet = option.name),
                                        (selectLocation = option.place)
                                "
                            >
                                {{ option.name }}
                                <span>{{ option.place }}</span>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="input-block">
                        <input type="text" name="name" id="name" placeholder="Ваше имя" v-model="name" />
                    </div>
                    <div class="inputs">
                        <div class="input-block">
                            <textarea
                                name="comment"
                                type="text"
                                id="comment"
                                placeholder="Напишите свой отзыв"
                                v-model="comment"
                            ></textarea>
                        </div>
                        <div class="input-block">
                            <input
                                type="text"
                                name="tel"
                                id="tel"
                                placeholder="+7 900 000 00 00"
                                v-mask="'+7 ### ### ## ##'"
                                v-model.trim="phone"
                            />
                        </div>
                    </div>
                    <div class="error">{{ errorText }}</div>
                    <div class="agree">
                        Указывая телефон, вы принимаете
                        <conf />
                    </div>
                    <div class="form__next">
                        <button
                            type="submit"
                            name="continue"
                            class="btn"
                            :class="comment.length > 3 && phone.length > 10 && rating ? `` : `_disable`"
                        >
                            Отправить
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import Conf from "../UI/Conf.vue";
export default {
    components: { Conf },
    name: "FeedbackPopup",
    data() {
        return {
            errorText: "",
            reservationId: "",
            name: "",
            phone: "",
            comment: "",
            title: "Оставьте ваш отзыв",
            isFanks: false,
            fanks: `Спасибо\n за ваш отзыв!`,
            rating: 0,
            starLimit: 5,
            shop_name: null,
            options: [
                {
                    id: 1,
                    value: "tatar",
                    name: "Кремль, 5",
                    place: "(Казанский кремль)",
                },
                {
                    id: 2,
                    value: "marjani",
                    name: "Марджани, 4",
                    place: "(Старо-татарская слобода)",
                },
            ],
        };
    },
    methods: {
        setRatingStar(star) {
            console.log(star);
            this.rating = star;
        },
        validForm() {
            if (this.rating === 0) {
                this.errorText = "Поставьте оценку";
            } else if (this.shop_name === "") {
                this.errorText = "Выберите ресторан";
            } else if (this.name === "") {
                this.errorText = "Напишите имя";
            } else if (this.comment === "") {
                this.errorText = "Напишите свой отзыв";
            } else if (this.phone === "") {
                this.errorText = "Укажите телефон";
            } else if (this.comment.length <= 3) {
                this.errorText = "Слишком короткий отзыв";
            } else if (this.phone.length <= 10) {
                this.errorText = "Телефон должен состоять из 11 цифр!";
            } else {
                axios
                    .post(
                        "https://admin.tcode.online/api/projects/tatar/site-requests/",
                        {
                            purpose: "Отзыв с сайта",
                            name: this.name,
                            comment: this.comment,
                            phone: this.phone,
                            rating: this.rating,
                            shop_id: 3,
                            shop_name: this.shop_name,
                        },
                        {
                            headers: {
                                // remove headers
                            },
                        }
                    )
                    .then((response) => {
                        console.log(response);
                        this.reservationId = response.data.id;
                        this.phone.length > 15 ? this.phone.length === 15 : 0;
                        this.errorText = "";
                        this.phone = "";
                        this.comment = "";
                        this.isFanks = true;
                    })
                    .catch((error) => {
                        console.log(error.response);
                    });
            }
        },
    },
    mounted() {
        if (this.shop_name === null) {
            this.shop_name = "";
            this.selectStreet = "Выберите ресторан";
            this.selectLocation = "";
        }

        let listRest = document.querySelector(".listRest");
        let arrow = document.querySelector(".arrow");
        let nameRests = document.querySelectorAll(".nameRest");
        let selectedRest = document.getElementById("selectedRest");
        let currentRest = selectedRest.querySelector(".currentRest");
        selectedRest.addEventListener("click", function () {
            listRest.classList.toggle("_showSelect");
            arrow.classList.toggle("_rotate");
        });
        for (let nameRest of nameRests) {
            nameRest.addEventListener("click", function () {
                currentRest.innerHTML = this.innerHTML;
                currentRest.style.color = "#2a374b";
            });
        }
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/popup-form.scss";
</style>
