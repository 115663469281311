<template>
    <div class="booking" id="booking">
        <div class="booking__containar">
            <h2 class="title">{{ title }}</h2>
            <button class="btn-booking" @click="$refs.reserve_popup.openPopup">
                <span>{{ btn_booking }}</span>
                <img src="@/assets/img/svg/arrow-right.svg" alt="arrow" />
            </button>
        </div>
        <div class="bg-img">
            <img src="@/assets/img/tulpan.png" alt="tulpan" class="mm" />
            <img src="@/assets/img/flowers.png" alt="flowers" class="md" />
        </div>
    </div>
    <reserve-popup ref="reserve_popup" />
</template>

<script>
import ReservePopup from "../popups/ReservePopup.vue";
export default {
    components: { ReservePopup },
    name: "BookingMain",
    data() {
        return {
            title: `Забронируйте стол\nв пару кликов`,
            btn_booking: "Забронировать",
        };
    },
    methods: {
        setBlock() {
            localStorage.setItem("block", "booking");
        },
    },
};
</script>

<style lang="scss">
.booking {
    background-color: #2a374b;
    height: 336px;
    overflow: hidden;
    padding: 70px 0;
    position: relative;
    @media (max-width: $ms3) {
        height: 216px;
        padding: 40px 0;
    }
    &__containar {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 1;
    }
    .title {
        color: #fff;
    }
}
</style>
