<template>
    <div class="restaurants" id="restaurants">
        <div class="restaurants__container">
            <div class="restaurants__body">
                <h2 class="title" id="rest">{{ title }}</h2>
                <div class="restaurants__address">
                    <div class="form_radio_btn" @click="showRestList1">
                        <input id="radio-1" type="radio" name="radio" value="1" checked />
                        <label for="radio-1">Кремль, 5</label>
                    </div>
                    <div class="form_radio_btn" @click="showRestList2">
                        <input id="radio-2" type="radio" name="radio" value="2" />
                        <label for="radio-2">Марджани, 4</label>
                    </div>
                </div>
                <div class="restaurants__list" v-if="isRestList1">
                    <ul>
                        <li>
                            <img :src="require(`@/assets/img/svg/${svg}`)" :alt="svg" width="28" />
                            Казанский Кремль
                        </li>
                        <li>
                            <img :src="require(`@/assets/img/svg/${svg}`)" :alt="svg" width="28" />
                            Музейность
                        </li>
                        <li>
                            <img :src="require(`@/assets/img/svg/${svg}`)" :alt="svg" width="28" />
                            Летняя терраса
                        </li>
                        <li>
                            <img :src="require(`@/assets/img/svg/${svg}`)" :alt="svg" width="28" />
                            Детская игровая
                        </li>
                    </ul>
                </div>
                <div class="restaurants__list" v-if="isRestList2">
                    <ul>
                        <li>
                            <img :src="require(`@/assets/img/svg/${svg}`)" :alt="svg" width="28" />
                            Старо-татарская слобода
                        </li>
                        <li>
                            <img :src="require(`@/assets/img/svg/${svg}`)" :alt="svg" width="28" />
                            Живописный вид
                        </li>
                        <li>
                            <img :src="require(`@/assets/img/svg/${svg}`)" :alt="svg" width="28" />
                            Летняя терраса
                        </li>
                    </ul>
                </div>
                <div class="tulpan mm">
                    <img src="@/assets/img/tulpan.png" alt="tulpan" />
                </div>
            </div>
            <div class="restaurants__slider">
                <swiper
                    :modules="modules"
                    :slides-per-view="3.4"
                    :space-between="20"
                    navigation
                    :pagination="{ clickable: true }"
                    :loop="true"
                    :breakpoints="{
                        320: {
                            slidesPerView: 1.5,
                            spaceBetween: 10,
                        },
                        768: {
                            slidesPerView: 3.4,
                            spaceBetween: 20,
                        },
                    }"
                >
                    <swiper-slide v-for="photo in getPhotos" :key="photo.id" class="ai" @click="showPopupId(photo.id)"
                        ><img :src="require(`@/assets/${path}/${rest}/${photo.img}`)" :alt="photo.img"
                    /></swiper-slide>
                </swiper>
            </div>
        </div>
        <div class="btn-block">
            <button class="btn-booking" @click="$refs.reserve_popup.openPopup1" v-if="rest == 'tatar'">
                <span>{{ bookToStreet }}</span>
                <img src="@/assets/img/svg/arrow-right.svg" alt="arrow" />
            </button>
            <button class="btn-booking" @click="$refs.reserve_popup.openPopup2" v-else>
                <span>{{ bookToStreet }}</span>
                <img src="@/assets/img/svg/arrow-right.svg" alt="arrow" />
            </button>
        </div>
        <div class="bg-img">
            <img src="@/assets/img/tulpan.png" alt="tulpan" class="mm" />
            <img src="@/assets/img/flowers.png" alt="flowers" class="md" />
        </div>
    </div>
    <popup-mask-image
        :class="popupMore ? '_show' : ''"
        @close="popupMore = false"
        v-for="photoPopup in getPhotos"
        :key="photoPopup.id"
        :popupId="popupId"
        :photoPopup="photoPopup"
        :path="path"
        :rest="rest"
    />
    <reserve-popup ref="reserve_popup" />
</template>

<script>
import { Navigation, Pagination, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import PopupMaskImage from "@/components/popups/PopupMaskImage.vue";
import ReservePopup from "../popups/ReservePopup.vue";
export default {
    name: "restaurantsPage",
    components: {
        Swiper,
        SwiperSlide,
        PopupMaskImage,
        ReservePopup,
    },
    setup() {
        return {
            modules: [Navigation, Pagination, A11y],
        };
    },
    data() {
        return {
            title: "Наши рестораны",
            svg: "tulpan.svg",
            isRestList1: true,
            isRestList2: false,
            path: "img/restaurants",
            tatar: [
                { id: 1, img: "2.jpg" },
                { id: 2, img: "1.jpg" },
                { id: 3, img: "3.jpg" },
                { id: 4, img: "4.jpg" },
                { id: 5, img: "5.jpg" },
                { id: 6, img: "6.jpg" },
                { id: 7, img: "7.jpg" },
                { id: 8, img: "8.jpg" },
                { id: 9, img: "9.jpg" },
                { id: 10, img: "10.jpg" },
            ],
            marjani: [
                { id: 1, img: "1.jpg" },
                { id: 2, img: "2.jpg" },
                { id: 3, img: "3.jpg" },
                { id: 4, img: "4.jpg" },
                { id: 5, img: "5.jpg" },
                { id: 6, img: "6.jpg" },
                { id: 7, img: "7.jpg" },
                { id: 8, img: "8.jpg" },
                { id: 9, img: "9.jpg" },
                { id: 10, img: "10.jpg" },
                { id: 11, img: "11.jpg" },
                { id: 12, img: "12.jpg" },
                { id: 13, img: "13.jpg" },
                { id: 14, img: "14.jpg" },
            ],
            popupMore: false,
            popupId: 0,
            bookToStreet: "Бронь в Кремле",
            isPopup: false,
            isPopupFeedback: false,
            rest: "tatar",
            street: "Кремль, 5",
            location: "(Казанский кремль)",
        };
    },
    methods: {
        showRestList1() {
            this.isRestList1 = true;
            this.isRestList2 = false;
            this.bookToStreet = "Бронь в Кремле";
            this.rest = "tatar";
            this.street = "Кремль, 5";
            this.location = "(Казанский кремль)";
        },
        showRestList2() {
            this.isRestList1 = false;
            this.isRestList2 = true;
            this.bookToStreet = "Бронь на Марджани";
            this.rest = "marjani";
            this.street = "Марджани, 4";
            this.location = " (Старо-татарская слобода)";
        },
        showPopupId(popupId) {
            console.log(popupId);
            this.popupId = popupId;
            this.popupMore = true;
        },
        setBlock() {
            localStorage.setItem("block", "restaurants");
        },
    },
    computed: {
        getPhotos() {
            return this.isRestList2 ? this.marjani : this.tatar;
        },
    },
};
</script>

<style lang="scss">
.restaurants {
    position: relative;
    background-color: #2a374b;
    padding-top: 50px;
    padding-bottom: 80px;
    overflow: hidden;
    @media (max-width: $ms3) {
        padding-top: 0;
        padding-bottom: 50px;
    }
    .bg-img {
        top: -150px;
        margin-bottom: -25%;
        @media (max-width: $ms3) {
            display: none;
        }
    }
    .title {
        color: #fff;
    }
    .btn-block {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        padding: 0 20px;
        .btn-booking {
            position: relative;
            z-index: 1;
        }
    }
    &__body {
        text-align: center;
        position: relative;
        .tulpan {
            position: absolute;
            bottom: -25%;
            right: -10%;
            width: 192px;
            height: 195.18px;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    &__address {
        margin-top: 30px;
        display: flex;
        justify-content: center;
    }

    &__list {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        padding: 0 20px;
        @media (max-width: $ms3) {
            justify-content: flex-start;
        }
        ul {
            li {
                font-size: 24px;
                font-weight: 600;
                line-height: 36px;
                color: #fff;
                display: flex;
                align-items: center;
                margin-top: 1rem;
                img {
                    margin-right: 10px;
                }
                @media (max-width: $ms3) {
                    font-size: 16px;
                    line-height: 28px;
                }
            }
        }
    }

    &__slider {
        margin-top: 50px;
    }
    .form_radio_btn {
        display: inline-block;
        margin-right: 30px;
        @media (max-width: $ms3) {
            margin-right: 5px;
            margin-left: 5px;
            width: 43%;
        }
    }
    .form_radio_btn input[type="radio"] {
        display: none;
    }
    .form_radio_btn label {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 0px 35px;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        border: 1.5px solid #fff;
        border-radius: 8px;
        user-select: none;
        min-width: 248px;
        height: 72px;
        color: #fff;
        @media (max-width: $ms3) {
            min-width: unset;
            height: 56px;
            font-size: 16px;
            line-height: 28px;
            padding: 0px 10px;
        }
    }

    /* Checked */
    .form_radio_btn input[type="radio"]:checked + label {
        background: #fff;
        color: #2a374b;
    }

    /* Hover */
    .form_radio_btn label:hover {
        background: #fff;
        color: #2a374b;
    }
    .swiper {
        &::before,
        &::after {
            box-shadow: inset 200px 0px 200px 0px #2a374b;
        }
        .swiper-wrapper {
            right: -90px;
            @media (max-width: $ms3) {
                right: 50%;
            }
        }
        .swiper-pagination {
            bottom: -40px;
            .swiper-pagination-bullet {
                background: #e5eefa;
            }
            .swiper-pagination-bullet-active {
                background: #fff;
            }
        }
        .ai {
            padding: 0 0 40% 0;
            @media (max-width: $ms3) {
                padding: 0 0 100% 0;
            }
        }
    }
}
</style>
