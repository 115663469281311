<template>
    <footer class="footer" id="footer">
        <div class="_container">
            <div class="footer__container">
                <div class="footer__top">
                    <h4 class="footer__title">
                        Подпишись, чтобы быть<br />
                        в курсе новостей
                    </h4>
                    <div class="footer__social">
                        <a
                            v-for="social in socials"
                            :key="social.id"
                            :href="social.link"
                            :class="social.link === '#' ? 'disabled' : ''"
                            ><img
                                :src="require(`@/assets/img/svg/${social.img}`)"
                                :alt="social.name"
                                :width="social.width"
                        /></a>
                    </div>
                </div>
                <div class="footer__arrow">
                    <img src="@/assets/img/svg/ornament.svg" alt="ornament" />
                </div>
                <div class="footer__bottom">
                    <div class="footer__pages">
                        <ul>
                            <li>
                                <a type="button" @click="$refs.reserve_popup.openPopup">Забронировать стол</a>
                            </li>
                            <li>
                                <a type="button" @click="openPopupCall">Заказать обратный звонок</a>
                            </li>
                            <li class="md">
                                <router-link v-if="username !== ''" to="/account">Личный кабинет</router-link>
                                <router-link v-else to="" @click="popupLogin = true"> Личный кабинет</router-link>
                            </li>
                        </ul>
                        <ul>
                            <li><a href="/#menu">Меню</a></li>
                            <li><a href="/#rest">Фото</a></li>
                            <li><a href="/#location">Как нас найти</a></li>
                            <li class="mm">
                                <router-link v-if="username !== ''" to="/account">Личный кабинет</router-link>
                                <router-link v-else to="" @click="popupLogin = true">Личный кабинет</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="footer__copy">© Татар, 2024</div>
                </div>
                <div class="footer__info">
                    <p>ООО "ТЮБЕТЕЙ УК" ОГРН: 1181690082573 ИНН: 1655408353</p>
                    <p>Телефон: +78432494141 Юр. адрес: 420021, РТ, г.Казань, ул.Габдуллы Тукая, д.64, пом.1301</p>
                </div>
            </div>
        </div>
    </footer>
    <call-popup v-if="isCallPopup" @click="isCallPopup = false" @close-popup-call="closePopupCall" />
    <login-popup v-if="popupLogin" @click="popupLogin = !popupLogin" @auth="userAuth" @close="closeLogin" />
    <reserve-popup ref="reserve_popup"/>
</template>

<script>
import CallPopup from "./popups/CallPopup.vue";
import LoginPopup from "@/components/popups/LoginPopup.vue";
import ReservePopup from "@/components/popups/ReservePopup.vue";
import axios from "axios";
export default {
    name: "FooterMain",
    components: {
        CallPopup,
        LoginPopup,
        ReservePopup
    },
    data() {
        return {
            isCallPopup: false,
            popupLogin: false,
            username: "",
            socials: [
                {
                    id: 1,
                    name: "vk",
                    link: "https://vk.com/tatar.kremlin",
                    img: "VK.svg",
                    width: "34.65",
                },
                /*{
          id: 2,
          name: "telegram",
          link: "#",
          img: "Telegram.svg",
          width: "32.67",
        },*/
            ],
            token: localStorage.getItem("token"),
        };
    },
    props: ["logo", "user"],
    emits: {
        authData: null,
    },
    methods: {
        openPopupCall() {
            this.isCallPopup = true;
        },
        closePopupCall() {
            this.isCallPopup = false;
        },
        closeLogin() {
            this.popupLogin = false;
        },
        async userAuth(token) {
            try {
                const response = await axios.get("https://admin.tcode.online/api/projects/tatar/profile/", {
                    headers: {
                        Authorization: "Token " + token,
                    },
                });
                console.log(response.data);
                localStorage.setItem("token", token);
                localStorage.setItem("username", response.data.username);
                localStorage.setItem("name", response.data.first_name);
                this.$emit("authData", response.data);
                this.popupLogin = false;
            } catch (err) {
                // Если есть ошибка
                alert(err);
            }
        },
        setBlock() {
            localStorage.setItem("block", "footer");
        },
    },
    mounted() {
        if (this.token) {
            axios
                .get("https://admin.tcode.online/api/projects/tatar/profile/", {
                    headers: {
                        Authorization: "Token " + this.token,
                    },
                })
                .then((response) => {
                    this.username = response.data.username;
                })
                .catch((error) => {
                    console.log(error.response);
                });
        }
    },
};
</script>

<style lang="scss">
.footer {
    background-color: #2a374b;
    padding: 50px 0 70px;
    &__container {
        max-width: 750px;
        margin: 0 auto;
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: $ms3) {
            flex-direction: column;
            text-align: center;
        }
    }

    &__title {
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        color: #fff;
        @media (max-width: $ms3) {
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
        }
    }

    &__social {
        display: flex;
        @media (max-width: $ms3) {
            margin-top: 20px;
        }
        a.disabled {
            pointer-events: none;
        }
        a {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            border: 2px solid #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 50px;
            @media (max-width: $ms3) {
                width: 40px;
                height: 40px;
                margin-left: 5px;
                margin-right: 5px;
                img {
                    transform: scale(0.75);
                }
            }
            &:hover,
            &:focus {
                background: #fff;
                transition: 0.2s ease 0.2s;
                img {
                    filter: invert(1);
                    transition: 0.2s ease 0.2s;
                }
            }
        }
    }

    &__arrow {
        width: 62px;
        height: 12px;
        margin: 50px auto;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            left: -45px;
            @media (max-width: $ms3) {
                left: 0;
            }
        }
    }

    &__bottom {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    &__pages {
        display: flex;
        flex: 1 1 auto;
        @media (max-width: $ms3) {
            flex-direction: column;
        }
        ul {
            width: 100%;
            @media (max-width: $ms3) {
                margin-top: 10px;
            }
            li {
                &:not(:first-child) {
                    margin-top: 20px;
                    @media (max-width: $ms3) {
                        margin-top: 10px;
                    }
                }
                a {
                    font-size: 18px;
                    line-height: 28px;
                    color: #e5eefa;
                    cursor: pointer;
                    @media (max-width: $ms3) {
                        font-size: 12px;
                        line-height: 24px;
                    }
                    &:hover,
                    &:focus {
                        color: #ccc;
                        transition: 0.2s ease 0.2s;
                    }
                }
            }
        }
    }

    &__copy {
        font-size: 12px;
        line-height: 18px;
        color: #e5eefa;
        @media (max-width: $ms3) {
            font-size: 8px;
            line-height: 14px;
        }
    }

    &__info {
        margin-top: 50px;
        color: #fff;
        line-height: 20px;
    }
}
</style>
