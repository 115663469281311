<template>
    <div class="banket-form">
        <div class="_container">
            <div class="banket-form__container">
                <div class="banket-form__body">
                    <h2 class="banket-form__title">
                        Банкетный зал<br />
                        для вашего никаха
                    </h2>
                    <ul class="banket-form__items">
                        <li class="banket-form__item">
                            <img
                                :src="require(`@/assets/img/svg/tulpan-item.svg`)"
                                alt="tulpan"
                                width="24"
                                height="24"
                            />
                            Сертифицированные халяль рестораны
                        </li>
                        <li class="banket-form__item">
                            <img
                                :src="require(`@/assets/img/svg/tulpan-item.svg`)"
                                alt="tulpan"
                                width="24"
                                height="24"
                            />
                            Удобное расположение залов в Казани
                        </li>
                        <li class="banket-form__item">
                            <img
                                :src="require(`@/assets/img/svg/tulpan-item.svg`)"
                                alt="tulpan"
                                width="24"
                                height="24"
                            />
                            Средний чек на гостя 1500 рублей
                        </li>
                    </ul>
                    <div class="banket-form__form-block display-ds">
                        <form @submit.prevent class="form">
                            <div class="input-block">
                                <label for="name">Ваше имя</label>
                                <input type="text" name="name" placeholder="Имя" id="name" v-model.trim="name" />
                            </div>
                            <div class="input-block">
                                <label for="tel">Номер телефона</label>
                                <input
                                    type="text"
                                    name="tel"
                                    v-mask="'+7 ### ### ## ##'"
                                    placeholder="+7 000 000 00 00"
                                    id="tel"
                                    v-model.trim="phone"
                                />
                            </div>
                            <div class="valid" :style="`color:${color}`">
                                {{ valid }}
                            </div>
                            <div class="input-block-flex">
                                <button type="submit" class="btn-send" @click="validForm">Отправить заявку</button>
                                <div class="agree">
                                    Указывая номер телефона, вы принимаете
                                    <conf />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <swiper
                    class="banket-form__slider"
                    :modules="modules"
                    :slides-per-view="1"
                    :space-between="20"
                    navigation
                    :pagination="{ clickable: true }"
                    :loop="true"
                    :breakpoints="{
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                    }"
                    :autoplay="{ delay: 2500 }"
                >
                    <swiper-slide class="banket-form__slide-img" v-for="slide in slides" :key="slide.id">
                        <img :src="require(`@/assets/img/banket-form/${slide.img}`)" alt="img" />
                    </swiper-slide>
                </swiper>
                <div class="banket-form__form-block display-ts">
                    <form @submit.prevent class="form">
                        <div class="input-block">
                            <label for="name">Ваше имя</label>
                            <input type="text" name="name" placeholder="Имя" id="name" v-model.trim="name" />
                        </div>
                        <div class="input-block">
                            <label for="tel">Номер телефона</label>
                            <input
                                type="text"
                                name="tel"
                                v-mask="'+7 ### ### ## ##'"
                                placeholder="+7 000 000 00 00"
                                id="tel"
                                v-model.trim="phone"
                            />
                        </div>
                        <div class="valid" :style="`color:${color}`">
                            {{ valid }}
                        </div>
                        <div class="input-block-flex">
                            <button type="submit" class="btn-send" @click="validForm">Отправить заявку</button>
                            <div class="agree">
                                Указывая номер телефона, вы принимаете
                                <conf />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Navigation, Pagination, A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import axios from "axios";
import Conf from "../UI/Conf.vue";
export default {
    components: {
        Swiper,
        SwiperSlide,
        Conf,
    },
    setup() {
        return {
            modules: [Navigation, Pagination, A11y, Autoplay],
        };
    },
    data() {
        return {
            name: "",
            phone: "",
            valid: "",
            color: "red",
            slides: [{ id: 1, img: "1.png" }],
        };
    },
    methods: {
        orderPost() {
            axios
                .post(
                    "https://admin.tcode.online/api/projects/tatar/site-requests/",
                    {
                        page: "Никах | Татар",
                        purpose: "Заявка на никах",
                        name: this.name,
                        phone: this.phone,
                        shop_id: 3,
                        shop_name: "Татар",
                    },
                    {
                        headers: {
                            // remove headers
                        },
                    }
                )
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.log(error.response);
                });
        },
        validForm() {
            this.color = "red";
            if (this.name === "") {
                this.valid = "Заполните поле Имя";
            } else if (this.phone === "") {
                this.valid = "Укажите телефон";
            } else if (this.name.length <= 1) {
                this.valid = "Слишком короткое имя";
            } else if (this.phone.length <= 15) {
                this.valid = "Телефон должен состоять из 11 цифр!";
            } else {
                this.orderPost();
                this.name = "";
                this.phone = "";
                this.color = "";
                this.valid = "Спасибо. Заявка успешно отправлена!";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.banket-form {
    background-color: #f6ece9;
    padding: 80px 0;
    @media (max-width: 992px) {
        padding: 50px 0;
    }
    .display-ds {
        @media (max-width: 992px) {
            display: none;
        }
    }
    .display-ts {
        display: none;
        @media (max-width: 992px) {
            display: flex;
        }
    }
    &__container {
        display: flex;
        @media (max-width: 992px) {
            flex-direction: column;
        }
    }

    &__body {
        flex: 1 1 auto;
        padding-right: 100px;
    }

    &__title {
        font-weight: 600;
        font-size: 26px;
        line-height: 40px;
        color: #2a374b;
        @media (max-width: 992px) {
            font-size: 20px;
            line-height: 24px;
        }
    }

    &__items {
        margin-top: 25px;
        @media (max-width: 992px) {
            margin-top: 35px;
        }
    }

    &__item {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #0d0d0d;
        display: flex;
        margin-top: 15px;
        img {
            margin-right: 10px;
        }
        @media (max-width: 992px) {
            font-size: 14px;
            line-height: 24px;
            margin-top: 25px;
        }
    }

    &__form-block {
        margin-top: 30px;
        .form {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
            }
        }
    }

    &__slider {
        min-width: 30%;
        max-width: 30%;
        height: 470px;
        overflow: hidden;
        @media (max-width: 992px) {
            min-width: 100%;
            max-width: 100%;
            height: 402px;
            margin-top: 50px;
        }
    }

    &__slide-img {
        border-radius: 16px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: inherit;
        }
    }
    .input-block {
        width: 48%;
        @media (max-width: 768px) {
            width: 100%;
            margin-top: 35px;
        }
        label {
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #2a374b;
            @media (max-width: 768px) {
                font-weight: 700;
                font-size: 16px;
                line-height: 21.6px;
            }
        }
        input {
            font-weight: 400;
            font-size: 20px;
            line-height: 32px;
            color: #2a374b;
            background: none;
            border: none;
            border-bottom: 1px solid #2a374b;
            border-radius: 0;
            padding: 0;
            &::placeholder {
                color: #b0b0b0;
            }
            @media (max-width: 768px) {
                font-size: 16px;
                line-height: 25.6px;
            }
        }
    }
    .valid {
        border: none;
        margin-top: 20px;
    }
    .input-block-flex {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 30px;
        @media (max-width: 768px) {
            flex-direction: column;
        }
    }
    .btn-send {
        width: 48%;
        height: 72px;
        border-radius: 8px;
        border: 1.5px solid #2a374b;
        background: none;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #2a374b;
        font-family: "Montserrat";
        &:hover,
        &:focus {
            background: #2a374b;
            color: #fff;
            transition: 0.2s ease 0.2s;
        }
        @media (max-width: 768px) {
            width: 80%;
            height: 56px;
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;
            order: 1;
            margin: 50px auto 0;
        }
    }
    .agree {
        font-weight: 400;
        font-size: 12px;
        line-height: 19.2px;
        color: #a9abb0;
        display: flex;
        flex-direction: column;
        margin: 0 20px;
        a {
            color: inherit;
            text-decoration: underline;
        }
    }
}
</style>
