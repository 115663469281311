<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <a href="/Политики_конфиденциальности_на_политику_обработки_Татар.pdf" target="_blank">{{ conf }}</a>
</template>

<script>
export default {
    data() {
        return {
            conf: "Политику обработки персональных данных",
        };
    },
};
</script>

<style lang="scss" scoped>
a {
    color: inherit;
    text-decoration: underline;
    margin-top: 5px;
    &:hover,
    &:focus {
        text-decoration: none;
        transition: 0.2s ease 0.2s;
    }
}
</style>
